const TWEEN = require('@tweenjs/tween.js');
const { getComputedLayer } = require('../helpers/commonHelper');
const { updateSourceOnCanvas } = require('./multipleSourcesHelper');

const { formatTimeToMilliseconds } = require('./Utils');
const {
  loopTypes,
  TWEEN_START_VALUE,
  foreverLoop,
  positions,
  typeNames
} = require('../constants/animation');
const { layers: layerTypes } = require('../constants/layers');
const { variantTypes } = require('../constants/variants');

/**
 * Checks if animations are applied to any of the ads in the template. Used externally in creativeSetHelper.js to determine whether to auto-open the timeline panel during template load.
 * @param {*} animations
 * @param {*} ads
 * @returns Boolean
 */
function areAnimationsApplied(animations, ads) {
  var hasAnimations = false;
  for (var k = 0; k < ads.length; k++) {
    if (ads[k].layers.find(ad => (ad.type === layerTypes.audio) || (ad.type === layerTypes.video))) {
      hasAnimations = true;
      break;
    }
    let adLocalAnimations = ads[k].localAnimations;
    let adHasLocalAnimations = (adLocalAnimations && adLocalAnimations.length > 0);
    let adAnimations = adHasLocalAnimations ? adLocalAnimations : animations;

    for (var i = 0; i < adAnimations.length; i++) {
      if (adAnimations[i].layerStartTime != 0 || adAnimations[i].layerEndTime != 5) {
        hasAnimations = true;
        break;
      }
      for (var j = 0; j < Object.values(positions).length; j++)
        if (adAnimations[i][Object.values(positions)[j]].type != typeNames.none) {
          hasAnimations = true;
          break;
        }
      if (hasAnimations)
        break;
    }
    if (hasAnimations)
      break;
  }
  return hasAnimations;
}

/**
 * Sets up a tween that will play the MediaElement (audio/video) - this element is syncd to the corresponding fabric object.
 * The tween is used in a "parallel tween"
 * @param {*} elements
 * @param {*} delay
 * @param {*} timeline
 * @param {*} startTime
 * @param {*} loopCount
 * @param {*} repeatDelay
 * @param {*} duration
 * @param {*} isDownload
 * @param {*} canvas
 * @returns TWEEN.Tween
 */
const playMediaElement = (elements, delay, timeline, startTime, loopCount, repeatDelay, duration, canvas, computedLayer, fabricObject) => {
  var o = { value: TWEEN_START_VALUE };
  const isLoopEnabled = computedLayer.type === layerTypes.video && computedLayer.data.props.loop;
  const videoDuration = formatTimeToMilliseconds(computedLayer.data.props.duration) / 1000; // in sec
  // this callback jumps the current time to video start time (if it was trimmed)
  // as soon it jumps, it removes itself from event listeners list
  const updateStartTime = async (event) => {
    event.target.removeEventListener('timeupdate', updateStartTime);
    let currentTime = event.target.currentTime;
    if (event.target.currentTime >= 0) {
      currentTime += computedLayer.data.props.startTime;
    }
    event.target.currentTime = currentTime;
    await new Promise((resolve, reject) => {
      event.target.addEventListener('seeked', (event) => {
        resolve();
      });
    });
  };
  // this callback is responsible for cutting the video off if it was trimmed at the end
  // also, if loop is enabled, it seeks the current time back to 0 (jump to video start time will be handled by first handler)
  const updateEndTime = async (event) => {
    if (event.target.currentTime >= computedLayer.data.props.endTime) {
      if (isLoopEnabled) {
        event.target.currentTime = 0;
        event.target.addEventListener('timeupdate', updateStartTime);
        if (event.target.paused) {
          event.target.play();
        }
      } else {
        event.target.removeEventListener('timeupdate', updateEndTime);
        event.target.currentTime = videoDuration + 1;
        event.target.pause();
        fabricObject.visible = false;
      }
    }
  };
  var tween = new TWEEN.Tween(o, timeline).to({ value: duration }, duration)
    .onStart(function () {
      var mediaElements = elements;
      mediaElements.forEach(async (x) => {
        if (!x.src) {
          return;
        }
        if (!computedLayer.data.props.useTts) {
          x.addEventListener('timeupdate', updateStartTime);
          x.addEventListener('timeupdate', updateEndTime);
        }
        x.currentTime = (startTime - delay) / 1000;
        if (computedLayer.type === layerTypes.video) {
          x.muted = computedLayer.data.styles.muted;
        }
        await new Promise((resolve, reject) => {
          x.addEventListener('seeked', (event) => {
            resolve();
          });
        });
        canvas.renderAll();
        x.play();
      });
    })
    .onComplete(function () {
      elements.forEach(e => {
        if (!e.src) {
          return;
        }
        e.pause();
        if (!computedLayer.data.props.useTts) {
          fabricObject.visible = true;
          e.removeEventListener('timeupdate', updateStartTime);
          e.removeEventListener('timeupdate', updateEndTime);
        }
      });
    })
    .onRepeat(function () {
      elements.forEach(x => {
        if (!x.src) {
          return;
        }
        if (!computedLayer.data.props.useTts) {
          fabricObject.visible = true;
          x.removeEventListener('timeupdate', updateStartTime);
          x.removeEventListener('timeupdate', updateEndTime);
          x.addEventListener('timeupdate', updateStartTime);
          x.addEventListener('timeupdate', updateEndTime);
        }
        x.currentTime = 0;
        x.play();
      });
    })
    .repeat(loopCount == loopTypes.forever ? foreverLoop : loopCount - 1)
    .repeatDelay(repeatDelay)
    .delay(delay);
  return tween;
}

/**
 * Sets the MediaElement's time
 * @param {MediaElement} element
 * @param {Number} time
 */
const changeCurrentTime = async (element, time) => {
  element.currentTime = time;
  await new Promise((resolve, reject) => {
    element.addEventListener('seeked', (event) => {
      resolve();
    });
  });
}

/**
 * Generates audio/video html elements to play in parallel with tweens while timeline animation is played.
 * This is done by creating a parallel tween just for video.
 * @param {*} state
 * @param {*} layer
 * @param {*} ad
 * @param {*} timeline
 * @param {*} animation
 * @param {*} ads
 * @param {*} tweenLayer
 * @param {*} tweenGroup
 * @param {*} timelineTime
 * @param {*} loopCount
 * @param {*} adVideos
 * @returns
 */
const generateHtmlMediaElements = async (state, layer, ad, timeline, animation, ads, tweenLayer, tweenGroup, timelineTime, loopCount, adVideos, computedLayer) => {
  let mediaElements, isAudioPresent = false;
  const fabricObject = layer.fabricObject;
  if (!fabricObject.visible) {
    return;
  }
  const layerDuration = (animation.layerEndTime * 1 - animation.layerStartTime * 1).toFixed(10) * 1000;
  if((layer.type == layerTypes.video || layer.type == layerTypes.audio)) {
    let mediaElement = fabricObject.getElement();
    var mediaLayer;
    // get current video layer src url.
    // If variant id is not null, it means current mode is variant mode. So get mediaLayer from variantLayers array.
    if(ad.variantId) mediaLayer = ad.variantLayers.find(l => l.id == layer.parentId);
    // if current mode is not variant mode
    // And if the meadia layer is edited in focus mode, get src url from locallayers array.
    if(!mediaLayer) mediaLayer = ad.localLayers.find(ll => {
      let props = ll.data && ll.data.props;
      return (ll.id == layer.parentId) && props && props.src;
    });
    if(!mediaLayer) mediaLayer = state.layers.find(l => l.id == layer.parentId);

    let layerData =  mediaLayer.data.audio || mediaLayer.data;
    if(!timeline.play && layer.type == layerTypes.video) {
      adVideos.push({
        adId: ad.adId,
        src: layerData.props.src,
        layerStartTime: animation.layerStartTime * 1000,
        layerEndTime: animation.layerEndTime * 1000,
        layer: computedLayer,
        duration: formatTimeToMilliseconds(computedLayer.data.props.duration),
        element: mediaElement
      });
    }
    mediaElements = [mediaElement];

    if(timeline.play)  {
      // load global video element with current video layer src.
      if(layer.type == layerTypes.video && ads.length == 1 && mediaElement.src != layerData.props.src)
      {
        mediaElement.src = layerData.props.src;
      }
      // repeatDelay is the delay time for the tween to repeat.
      var repeatDelay = ad.maxAdEndTime * 1000 - layerDuration;
      tweenLayer.parallelTween = playMediaElement(
        mediaElements,
        animation.layerStartTime * 1000,
        tweenGroup.group,
        timelineTime,
        loopCount,
        repeatDelay,
        layerDuration,
        ad.canvas,
        computedLayer,
        fabricObject
      );
    }
  }
  return isAudioPresent;
}

/**
 * Helper function for timeline drag.
 * Updates all tween as well as parallel tween to time specified in timeline payload.
 * Also updates current time of adVideos.
 * @param {*} tweenGroup
 * @param {*} timelineTime
 * @param {*} ad
 * @param {*} tweenGroups
 * @param {*} resetGroup
 * @param {*} adVideos
 * @param {*} groupedVideos
 * @param {*} ads
 * @param {*} index
 */
const timelineDrag = async (tweenGroup, timelineTime, ad, tweenGroups, resetGroup, adVideos, groupedVideos, ads, index, state) => {
  tweenGroup.tweenLayers.forEach(x => {
    if(x.tweens.length > 0) x.tweens[0].start(0)
    if(x.parallelTween) x.parallelTween.start(0)
  });
  await updateSources(state, ad, timelineTime);
  tweenGroup.group.update(timelineTime);
  ad.canvas.renderAll();
  tweenGroup.group.removeAll();
  tweenGroups.forEach(x => {
    x.additionalGroups.forEach(y=>y.group.removeAll());
  });
  resetGroup.removeAll();
  adVideos.forEach(x => {
    // group videos based on source and video layer startime
    // And store array of all 'canvasIds' and 'maximumDuration' of all grouped video layers.
    const groupedVideo = groupedVideos.find(gv => gv.src == x.src && gv.layerStartTime == x.layerStartTime);
    if(groupedVideo) {
      groupedVideo.canvasIds.push(x.adId);
      groupedVideo.elements.push(x.element);
      if(groupedVideo.duration < x.duration) { groupedVideo.duration = x.duration; }
    }
    else groupedVideos.push({
      src: x.src,
      layerStartTime: x.layerStartTime,
      layerEndTime: x.layerEndTime,
      canvasIds: [x.adId],
      duration: x.duration,
      elements: [x.element],
      loop: x.layer.data.props.loop,
      videoStartTime: x.layer.data.props.startTime * 1000,
      videoEndTime: x.layer.data.props.endTime * 1000
    });
  })
  if(ads.length == index + 1){
    for(var i = 0; i < groupedVideos.length; i++) {
      let canvases = ads.filter(ad => groupedVideos[i].canvasIds.includes(ad.adId)).map(ad => ad.canvas);
      const groupedVideo = groupedVideos[i];
      let duration = groupedVideo.duration;
      if (groupedVideo.videoEndTime - groupedVideo.videoStartTime < duration) {
        duration = groupedVideo.videoEndTime - groupedVideo.videoStartTime;
      }
      let newCurrenTime = -1;
      if (timelineTime >= groupedVideo.layerStartTime
        && timelineTime <= groupedVideo.layerEndTime
        && (timelineTime <= (groupedVideo.layerStartTime + duration) || groupedVideo.loop)
      ) {
        newCurrenTime = (timelineTime - groupedVideo.layerStartTime + groupedVideo.videoStartTime) / 1000;
        // if loop is enabled and timelineTime goes out of bound of video duration,
        // we need to make sure timeleneTime is always under video start and end time.
        // using a simple modulo function to acheive this.
        if (groupedVideo.loop && timelineTime > (groupedVideo.layerStartTime + duration)) {
          newCurrenTime = (((timelineTime - groupedVideo.layerStartTime) % duration) + groupedVideo.videoStartTime) / 1000;
        }
      }
      // Updating the current time for every element to have the correct videoframe when seeked
      for (const adElement of groupedVideo.elements) {
        await changeCurrentTime(adElement, newCurrenTime);
      }
      canvases.forEach(c => {
        c.renderAll();
      });
    }
  }
}

//Change image fabric object in animations
const updateSources = async (state, ad, currentTime) => {
  const imageLayers = ad.layers.filter(l => l.type == layerTypes.image);
  await imageLayers.forEachAsync(async layer => {
    const animation = state.animations.find(a => a.layerId === layer.parentId);
    const computedLayer = getComputedLayer(state.layers, ad, layer.parentId);
    const multipleSources = computedLayer.data.multipleSources;
    if(multipleSources) {
      //Change imageSrc before starting animations
      const animationSources = animation.sources;
      for (let index = 0; index < animationSources.length; index++) {
        const source = animationSources[index];
        const endTime = source.end.endTime * 1000;
        const startTime = source.start.startTime * 1000;
        if (index == 0 && currentTime < startTime) {
          Object.assign(layer.fabricObject, { opacity: animateConstants.minOpacity });
          ad.canvas.renderAll();
        }
        else if (index >= 0 && index < animationSources.length - 1 && currentTime > endTime) {
          let nextSourceStartTime = animationSources[index + 1].start.startTime * 1000;
          if (currentTime < nextSourceStartTime) {
            Object.assign(layer.fabricObject, { opacity: animateConstants.minOpacity });
            ad.canvas.renderAll();
          }
        }
        else if (currentTime < startTime || currentTime > endTime) continue;
        else if (currentTime >= startTime && currentTime <= endTime) {
          let sourceToUpdate = computedLayer.data.props.sources[index];
          if (layer.fabricObject.getSrc() != sourceToUpdate.imageUrl) {
            const isInDefaultVariant = state.variantConfiguration.variants[0].id === state.variantConfiguration.selectedVariant[variantTypes.Segment].id;
            await updateSourceOnCanvas({ ad, layerToUpdate: layer, data: computedLayer.data, source: sourceToUpdate, isInDefaultVariant });
            if (layer.fabricObjectBackUp) {
              Object.assign(layer.fabricObjectBackUp, layer.fabricObject);
            }
          }
        }
      }
    }
  });
}

const timelineHelper = {
  areAnimationsApplied,
  updateSources,
  generateHtmlMediaElements,
  timelineDrag
}

module.exports = {
  timelineHelper
};
