/**
 * Initializes ffmpeg in-browser - https://github.com/ffmpegwasm/ffmpeg.wasm#usage
 * This method is called once when download video is invoked
 * @returns ffmpeg instance
 */
// AY TODO: Would be ideal to move this out to "Encoder" functionality

var ffmpegLogs = [];
var setLogging = false;

async function initFFmpeg() {
  // FFmpeg instance is globally added using script tag in the index.html page
  // https://github.com/ffmpegwasm/ffmpeg.wasm#installation > "Browser"

  const { createFFmpeg } = FFmpeg;
  var ffmpeg = createFFmpeg({ log: false });
  // eslint-disable-next-line
  console.log('Loading ffmpeg-core.js');
  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }
  ffmpeg.setLogger(({ type, message }) => {
    if (!setLogging) {
      return;
    }
    ffmpegLogs.push(message);
  });
  return ffmpeg;
}

function setFfmpegLogging(value) {
  setLogging = value;
}

function getFfmpegLogs() {
  return ffmpegLogs;
}

function clearFfmpegLogs() {
  ffmpegLogs = [];
}

module.exports = {
  initFFmpeg,
  setFfmpegLogging,
  getFfmpegLogs,
  clearFfmpegLogs
};